import { Journey } from '@/lib/strapi-types/Journey';

export default function getJourneySlug({
  destination,
  journey
}: {
  destination: { attributes: { urlPath?: string } };
  journey: {
    attributes: { generalTravelType?: Journey['attributes']['generalTravelType']; slug?: string };
  };
}): string {
  if (!journey.attributes.generalTravelType) return '/';

  const slug = `/${destination.attributes.urlPath}/${journey.attributes.generalTravelType.replaceAll(
    '_',
    '-'
  )}/${journey.attributes.slug}`;

  return slug;
}
